<template>
  <footer :class="['container', $style.footer]">
    <div :class="$style.top">
      <div :class="$style.logo">
        <IconsLogo :class="$style.logoIcon"/>
      </div>

      <FooterNav :class="$style.nav" />

      <div :class="$style.subscribe">
        <div :class="$style.label">{{ t('footer.subscribe') }}</div>
        {{ t('footer.text') }}
        <div :class="$style.socialList">
          <a href="https://t.me/zerodetect" @click="tracking" target="_blank">
            <UiIconsTg />
          </a>
          <a v-if="false" href="https://youtube.com/@aqumbrowser" @click="tracking" target="_blank">
            <UiIconsYouTube />
          </a>
          <a href="https://www.instagram.com/0detect" @click="tracking" target="_blank">
            <UiIconsInsta />
          </a>
        </div>
      </div>
    </div>
    <div :class="$style.underFooter">
      <div :class="$style.cr">© 2024 Green advex technology llc</div>

      <nuxt-link :to="localePath('/documents/privacy')">{{ t('footer.privacy') }}</nuxt-link>
      <nuxt-link :to="localePath('/documents/terms')">{{ t('footer.terms') }}</nuxt-link>
    </div>
  </footer>
</template>

<script setup lang="ts">
const { t } = useI18n();

const tracking = () => {
  fbq('track', 'Search');
}
</script>

<style lang="scss" module>
  .footer {
    @media (min-width: 1024px) {
      margin-top: 36px;
    }

    a {
      display: flex;
      color: #71787F;
      text-decoration: none;
      transition: 200ms ease;

      &:hover {
        color: #293EFF;
      }
    }
  }

  .logo {
    flex-shrink: 0;
    width: 106px;
    height: auto;

    @media (max-width: 767px) {
      order: 1;
    }
  }

  .nav {
    flex: 0.8;

    @media (max-width: 767px) {
      order: 3;
      flex: unset;
      width: 100%;
    }
  }

  .subscribe {
    width: 260px;
    font-size: 12px;
    color: #71787F;

    @media (max-width: 767px) {
      order: 2;
    }
  }

  .label {
    margin-bottom: 14px;
    color: #121E29;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-size: 16px;

    @media (max-width: 1023px) {
      font-size: 14px;
    }
  }

  .social {
    &List {
      display: flex;
      gap: 10px;
      margin-top: 14px;

      svg {
        flex: 1;
        height: 34px;
        width: 34px;
      }
    }
  }

  .top {
    display: flex;
    justify-content: space-between;
    gap: 64px;

    @media (max-width: 767px) {
      flex-direction: column;
      gap: 34px;
    }

    a {
      font-family: 'Gilroy';
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
    }
  }

  .underFooter {
    display: flex;
    align-items: center;
    gap: 30px;
    padding: 70px 0 45px 0;
    color: #121E29;
    font-family: 'Gilroy';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media (max-width: 1023px) {
      padding: 26px 0;
    }

    @media (max-width: 767px) {
      padding-top: 34px;
    }

    @media (max-width: 375px) {
      flex-direction: column;
      justify-content: center;
      gap: 15px;

      .cr {
        margin-right: 0;
      }
    }
  }

  .cr {
    margin-right: auto;
  }
</style>