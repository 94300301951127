<template>
  <svg viewBox="0 0 100 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M93.9421 12.13H90.8457V9.4375H100V12.13H96.9039V28.2851H93.9421V12.13Z" fill="#121E29"/>
    <path d="M85.4908 28.5546C84.0727 28.5546 82.9867 28.1507 82.2328 27.3429C81.4969 26.5352 81.1289 25.3954 81.1289 23.9234V13.7996C81.1289 12.3277 81.4969 11.1878 82.2328 10.3801C82.9867 9.57233 84.0727 9.16846 85.4908 9.16846C86.9088 9.16846 87.9858 9.57233 88.7218 10.3801C89.4757 11.1878 89.8527 12.3277 89.8527 13.7996V15.792H87.0524V13.6111C87.0524 12.4444 86.5588 11.861 85.5716 11.861C84.5843 11.861 84.0907 12.4444 84.0907 13.6111V24.1389C84.0907 25.2877 84.5843 25.8621 85.5716 25.8621C86.5588 25.8621 87.0524 25.2877 87.0524 24.1389V21.2579H89.8527V23.9234C89.8527 25.3954 89.4757 26.5352 88.7218 27.3429C87.9858 28.1507 86.9088 28.5546 85.4908 28.5546Z" fill="#121E29"/>
    <path d="M71.5391 9.4375H79.6166V12.13H74.5008V17.1112H78.5665V19.8037H74.5008V25.5926H79.6166V28.2851H71.5391V9.4375Z" fill="#121E29"/>
    <path d="M64.0456 12.13H60.9492V9.4375H70.1038V12.13H67.0074V28.2851H64.0456V12.13Z" fill="#121E29"/>
    <path d="M51.9492 9.4375H60.0268V12.13H54.911V17.1112H58.9767V19.8037H54.911V25.5926H60.0268V28.2851H51.9492V9.4375Z" fill="#121E29"/>
    <path d="M41.0098 9.4375H45.5332C47.0051 9.4375 48.109 9.8324 48.845 10.6222C49.5809 11.412 49.9489 12.5698 49.9489 14.0956V23.6271C49.9489 25.1528 49.5809 26.3106 48.845 27.1004C48.109 27.8902 47.0051 28.2851 45.5332 28.2851H41.0098V9.4375ZM45.4793 25.5926C45.964 25.5926 46.332 25.449 46.5833 25.1618C46.8525 24.8746 46.9872 24.4079 46.9872 23.7617V13.9609C46.9872 13.3147 46.8525 12.848 46.5833 12.5608C46.332 12.2736 45.964 12.13 45.4793 12.13H43.9715V25.5926H45.4793Z" fill="#121E29"/>
    <g filter="url(#filter0_d_2_2)">
    <rect x="4" y="3" width="31.7227" height="31.7227" rx="8" fill="url(#paint0_linear_2_2)"/>
    </g>
    <path d="M27.7132 11.4758L26.5371 10.3867L12.025 26.0584L13.2011 27.1474L27.7132 11.4758Z" fill="white"/>
    <path d="M15.4539 13.6817C16.5616 12.2091 18.1384 11.2839 19.8977 11.2969C20.9793 11.2969 21.9958 11.6618 22.8689 12.2873C23.6378 12.8347 24.3024 13.5905 24.8106 14.5027L26.4526 12.7304C24.9279 10.3847 22.5431 8.87305 19.8586 8.87305C17.1741 8.87305 15.2324 10.1111 13.7207 12.0919L15.4539 13.6948V13.6817Z" fill="white"/>
    <path d="M25.9037 18.79C25.9037 20.1714 25.5909 21.4615 25.0566 22.5692L26.8289 24.2112C27.689 22.6474 28.1973 20.7839 28.1973 18.777C28.1973 16.7701 27.8454 15.493 27.2069 14.0986L25.4737 15.9752C25.7604 16.8483 25.9167 17.7996 25.9037 18.8031V18.79Z" fill="white"/>
    <path d="M14.2666 21.5659C13.9799 20.6928 13.8235 19.7415 13.8365 18.7381C13.8365 17.3567 14.1493 16.0666 14.6836 14.9589L12.9113 13.3169C12.0512 14.8807 11.543 16.7442 11.543 18.7511C11.543 20.758 11.8948 22.0351 12.5334 23.4294L14.2666 21.5529V21.5659Z" fill="white"/>
    <path d="M24.2884 23.8591C23.1808 25.3317 21.6039 26.2569 19.8447 26.2439C17.7987 26.2439 16.0003 24.9668 14.9057 23.0381L13.2637 24.8104C14.7884 27.1561 17.1732 28.6678 19.8577 28.6678C22.5422 28.6678 24.4839 27.4297 25.9956 25.4489L24.2624 23.846L24.2884 23.8591Z" fill="white"/>
    <defs>
    <filter id="filter0_d_2_2" x="0" y="0" width="39.7227" height="39.7227" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1"/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_2"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2_2" result="shape"/>
    </filter>
    <linearGradient id="paint0_linear_2_2" x1="19.8614" y1="3" x2="19.8614" y2="34.7227" gradientUnits="userSpaceOnUse">
    <stop stop-color="#2F3332"/>
    <stop offset="1" stop-color="#141617"/>
    </linearGradient>
    </defs>
  </svg>
</template>